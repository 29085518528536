export const dataConstants = {
    LOADING:"LOADING",
    GET_LOANS:"GET_LOANS",
    GET_APPLIED_LOANS:"GET_APPLIED_LOANS",
    GET_SUBMITTED_LOANS:"GET_SUBMITTED_LOANS",
    GET_LOAN_DETAILED:"GET_LOAN_DETAILED",
    GET_FUNDS:"GET_FUNDS",
    GET_FUND_INCREMENT_BY_ADMIN:"GET_FUND_INCREMENT_BY_ADMIN",
    GET_DUES:"GET_DUES",
    RESPONSE:"RESPONSE",
    GET_SYSTEM_USERS:"GET_SYSTEM_USERS",
    GET_MEMBERS:"GET_MEMBERS",
    GET_A_MEMBER:"GET_A_MEMBER",
    GET_TOTAL:"GET_TOTAL",
    GET_TOTAL_LOANS_STATS:"GET_TOTAL_LOANS_STATS",
    GET_LOANS_STATISTICS:"GET_LOANS_STATISTICS",
    GET_TOTAL_ACTIVE_NURSE:"GET_TOTAL_ACTIVE_NURSE",
  
   
    
}