/* eslint-disable  */
import { createElement } from "react";
import { ApiEndpoints } from "../Axios/apiEndpoints";
import axiosInstance from "../Axios/axios";
import { dataConstants } from "../Constants/dataConstant";

export const GetAppliedLoans = (
  isPaginated,
  page,
  size,
  status,
  // sort,
  search,
  filter
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: dataConstants.LOADING,
        isLoading: true,
      });
      // ?status=${status}
      const response = await axiosInstance.get(
        `/loans/applied-loans?isPaginated=${isPaginated}&page=${page}&size=${size}&status=${status}${
          search ? search : ""
        }${filter ? filter : ""}`
      );
      // const response = await axiosInstance.get(`/loans/applied-loans${status && `?status=`+status }`);

      if (response) {
        // dispatch({
        //   type: dataConstants.GET_APPLIED_LOANS,
        //   payload: { appliedLoans: response.data },
        // });
        const {
          docs,
          page: currentPage,
          totalItems,
          totalPages,
          currentPageSize,
          links,
          size: currentSize,
        } = response.data;

        dispatch({
          type: dataConstants.GET_APPLIED_LOANS,
          payload: {
            appliedLoans: docs,
            paginate: {
              total: totalPages,
              totalItems,
              page: currentPage,
              size: currentSize,
              previousPage: links?.previousPage,
              currentPageSize,
              nextPage: links?.nextPage,
              previousNumber: links?.previous,
              nextNumber: links?.next,
            },
          },
          // payload: { funds: response.data}
        });
        dispatch({
          type: dataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: dataConstants.RESPONSE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Connection failed. Try again later",
        },
      });
      dispatch({
        type: dataConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const GetArchievedLoans = (
  isPaginated,
  page,
  size,
  status,
  search,
  filter
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: dataConstants.LOADING,
        isLoading: true,
      });
      // ?status=${status}
      const response = await axiosInstance.get(
        `/archieves/archieved-loans?isPaginated=${isPaginated}&page=${page}&size=${size}${
          search ? search : ""
        }${filter ? filter : ""}`
      );
      // const response = await axiosInstance.get(`/loans/applied-loans${status && `?status=`+status }`);

      if (response) {
        // dispatch({
        //   type: dataConstants.GET_APPLIED_LOANS,
        //   payload: { appliedLoans: response.data },
        // });
        const {
          docs,
          page: currentPage,
          totalItems,
          totalPages,
          currentPageSize,
          links,
          size: currentSize,
        } = response.data;

        dispatch({
          type: dataConstants.GET_APPLIED_LOANS,
          payload: {
            appliedLoans: docs,
            paginate: {
              total: totalPages,
              totalItems,
              page: currentPage,
              size: currentSize,
              previousPage: links?.previousPage,
              currentPageSize,
              nextPage: links?.nextPage,
              previousNumber: links?.previous,
              nextNumber: links?.next,
            },
          },
          // payload: { funds: response.data}
        });
        dispatch({
          type: dataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: dataConstants.RESPONSE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Connection failed. Try again later",
        },
      });
      dispatch({
        type: dataConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const GetLoanDetailed = (loan_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: dataConstants.LOADING,
        isLoading: true,
      });
      
      const response = await axiosInstance.get(`/loans/detailed/${loan_id}`);

      if (response) {
    
        dispatch({
          type: dataConstants.GET_LOAN_DETAILED,
          payload: {
            loan_detailed: response.data },
          
        });
        dispatch({
          type: dataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: dataConstants.RESPONSE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Connection failed. Try again later",
        },
      });
      dispatch({
        type: dataConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const RebookLoanByLoanOfficer = (loan_id, body) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: dataConstants.LOADING,
        isLoading: true,
      });
      
      const response = await axiosInstance.put(`/loans/rebook-loan/${loan_id}`, body);

      if (response) {
    
        dispatch({
          type: dataConstants.GET_LOAN_DETAILED,
          payload: {
            loan_detailed: response.data },
          
        });
        dispatch({
          type: dataConstants.RESPONSE,
          response: {
            state: "UPDATED",
            message:  "Rebook updated",
          },
        });
        dispatch({
          type: dataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: dataConstants.RESPONSE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Connection failed. Try again later",
        },
      });
      dispatch({
        type: dataConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const GetAppliedLoansSubmitted = (
  isPaginated,
  page,
  size,
  search,
  filter
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: dataConstants.LOADING,
        isLoading: true,
      });
      // ?status=${status}
      const response = await axiosInstance.get(
        `/loans/applied-loans/submitted?isPaginated=${isPaginated}&page=${page}&size=${size}${
          search ? search : ""
        }${filter ? filter : ""}`
      );
      // const response = await axiosInstance.get(`/loans/applied-loans${status && `?status=`+status }`);

      if (response) {
        // dispatch({
        //   type: dataConstants.GET_APPLIED_LOANS,
        //   payload: { appliedLoans: response.data },
        // });
        const {
          docs,
          page: currentPage,
          totalItems,
          totalPages,
          currentPageSize,
          links,
          size: currentSize,
        } = response.data;

        dispatch({
          type: dataConstants.GET_SUBMITTED_LOANS,
          payload: {
            submittedLoans: docs,
            paginate: {
              total: totalPages,
              totalItems,
              page: currentPage,
              size: currentSize,
              previousPage: links?.previousPage,
              currentPageSize,
              nextPage: links?.nextPage,
              previousNumber: links?.previous,
              nextNumber: links?.next,
            },
          },
          // payload: { funds: response.data}
        });
        dispatch({
          type: dataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: dataConstants.RESPONSE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Connection failed. Try again later",
        },
      });
      dispatch({
        type: dataConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const GetIncompleteLoanApplications = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: dataConstants.LOADING,
        isLoading: true,
      });
      // ?status=${status}
      const response = await axiosInstance.get(
        `/loans/applied-loans-incomplete`
      );
      // const response = await axiosInstance.get(`/loans/applied-loans${status && `?status=`+status }`);

      if (response) {
        dispatch({
          type: dataConstants.GET_APPLIED_LOANS,
          payload: {
            appliedLoans: response.data,
          },
          // payload: { funds: response.data}
        });
        dispatch({
          type: dataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: dataConstants.RESPONSE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Connection failed. Try again later",
        },
      });
      dispatch({
        type: dataConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const GetInitiatedIncompleteLoanApplications = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: dataConstants.LOADING,
        isLoading: true,
      });
      // ?status=${status}
      const response = await axiosInstance.get(
        `/loans/applied-loans-initiated-incomplete`
      );
      // const response = await axiosInstance.get(`/loans/applied-loans${status && `?status=`+status }`);

      if (response) {
        dispatch({
          type: dataConstants.GET_APPLIED_LOANS,
          payload: {
            appliedLoans: response.data,
          },
          // payload: { funds: response.data}
        });
        dispatch({
          type: dataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: dataConstants.RESPONSE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Connection failed. Try again later",
        },
      });
      dispatch({
        type: dataConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const GetSystemUsers = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: dataConstants.LOADING,
        isLoading: true,
      });

      const response = await axiosInstance.get(
        ApiEndpoints.GET_ALL_SYSTEM_USERS
      );

      if (response) {
        dispatch({
          type: dataConstants.GET_SYSTEM_USERS,
          payload: { system_users: response.data },
        });
        dispatch({
          type: dataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: dataConstants.RESPONSE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Connection failed. Try again later",
        },
      });
      dispatch({
        type: dataConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const GetMembers = (isPaginated, page, size, search) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: dataConstants.LOADING,
        isLoading: true,
      });

      const response = await axiosInstance.get(
        `/users/members?isPaginated=${isPaginated}&page=${page}&size=${size}${
          search ? search : ""
        }`
      );
      // const response = await axiosInstance.get(
      //   `/users/members`
      // );

      // console.log('response.members', response)

      if (response) {
        const {
          docs,
          page: currentPage,
          totalItems,
          totalPages,
          currentPageSize,
          links,
          size: currentSize,
        } = response.data;

        dispatch({
          type: dataConstants.GET_MEMBERS,
          payload: {
            members: docs,
            paginate: {
              total: totalPages,
              totalItems,
              page: currentPage,
              size: currentSize,
              previousPage: links?.previousPage,
              currentPageSize,
              nextPage: links?.nextPage,
              previousNumber: links?.previous,
              nextNumber: links?.next,
            },
          },
          // payload: { funds: response.data}
        });
        dispatch({
          type: dataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: dataConstants.RESPONSE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Connection failed. Try again later",
        },
      });
      dispatch({
        type: dataConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const GetOneMember = (staff_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: dataConstants.LOADING,
        isLoading: true,
      });

      const response = await axiosInstance.get(
        `/users/members/${staff_id}`
      );
   

      if (response) {
     

        dispatch({
          type: dataConstants.GET_A_MEMBER,
          payload: {
            member: response.data,
          },
          // payload: { funds: response.data}
        });
        dispatch({
          type: dataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: dataConstants.RESPONSE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Connection failed. Try again later",
        },
      });
      dispatch({
        type: dataConstants.LOADING,
        isLoading: false,
      });
    }
  };
};

export const MarkedAsSubmittedLoan = (loan_id, body) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: dataConstants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.put(
        `/loans/my-applied-loans/submitted-status/${loan_id}`,
        body
      );

      if (response) {
        dispatch({
          type: dataConstants.RESPONSE,
          response: {
            state: "SUCCESS",
            message: "Status marked as submitted",
          },
        });
        dispatch({
          type: dataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: dataConstants.RESPONSE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Connection failed. Try again later",
        },
      });
      dispatch({
        type: dataConstants.LOADING,
        isLoading: false,
      });
    }
  };
};

export const MarkedAsBookedAppliedLoans = (loan_id, body) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: dataConstants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.put(
        `/loans/my-applied-loans/booked-status/${loan_id}`,
        body
      );

      if (response) {
        dispatch({
          type: dataConstants.RESPONSE,
          response: {
            state: "SUCCESS",
            message: "Status marked as booked",
          },
        });
        dispatch({
          type: dataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: dataConstants.RESPONSE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Connection failed. Try again later",
        },
      });
      dispatch({
        type: dataConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const MarkedAsInitiatedAppliedLoans = (loan_id, body) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: dataConstants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.put(
        `/loans/my-applied-loans/initiated-status/${loan_id}`,
        body
      );

      if (response) {
        dispatch({
          type: dataConstants.RESPONSE,
          response: {
            state: "SUCCESS",
            message: "Status marked as Initiated",
          },
        });
        dispatch({
          type: dataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: dataConstants.RESPONSE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Connection failed. Try again later",
        },
      });
      dispatch({
        type: dataConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const MarkedAsAuthorizedAppliedLoans = (loan_id, body) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: dataConstants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.put(
        `/loans/my-applied-loans/authorized-status/${loan_id}`,
        body
      );

      if (response) {
        dispatch({
          type: dataConstants.RESPONSE,
          response: {
            state: "SUCCESS",
            message: "Status marked as authorized",
          },
        });
        dispatch({
          type: dataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: dataConstants.RESPONSE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Connection failed. Try again later",
        },
      });
      dispatch({
        type: dataConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const MarkedAsPaidAppliedLoans = (loan_id, body) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: dataConstants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.put(
        `/loans/my-applied-loans/paid-status/${loan_id}`,
        body
      );

      if (response) {
        dispatch({
          type: dataConstants.RESPONSE,
          response: {
            state: "SUCCESS",
            message: "Status marked as Paid",
          },
        });
        dispatch({
          type: dataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: dataConstants.RESPONSE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Connection failed. Try again later",
        },
      });
      dispatch({
        type: dataConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const MarkedAsRejectedAppliedLoans = (loan_id, body) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: dataConstants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.put(
        `/loans/my-applied-loans/rejected-status/${loan_id}`,
        body
      );

      if (response) {
        dispatch({
          type: dataConstants.RESPONSE,
          response: {
            state: "SUCCESS",
            message: "Status marked as Rejected",
          },
        });
        dispatch({
          type: dataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: dataConstants.RESPONSE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Connection failed. Try again later",
        },
      });
      dispatch({
        type: dataConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const MarkedAsRejectedAppliedLoansByLoanHeads = (loan_id, body) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: dataConstants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.put(
        `/loans/reject-status-by-loan-heads/${loan_id}`,
        body
      );

      if (response) {
        dispatch({
          type: dataConstants.RESPONSE,
          response: {
            state: "SUCCESS",
            message: "Status marked as Rejected",
          },
        });
        dispatch({
          type: dataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: dataConstants.RESPONSE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Connection failed. Try again later",
        },
      });
      dispatch({
        type: dataConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const MarkedAsRejectedAppliedLoansByAdmin = (loan_id, body) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: dataConstants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.put(
        `/loans/reject-status-by-loan-admin/${loan_id}`,
        body
      );

      if (response) {
        dispatch({
          type: dataConstants.RESPONSE,
          response: {
            state: "SUCCESS",
            message: "Status marked as Rejected",
          },
        });
        dispatch({
          type: dataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: dataConstants.RESPONSE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Connection failed. Try again later",
        },
      });
      dispatch({
        type: dataConstants.LOADING,
        isLoading: false,
      });
    }
  };
};

export const GetAllFunds = (isPaginated, page, size, search) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: dataConstants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.get(
        `/funds?isPaginated=${isPaginated}&page=${page}&size=${size}${
          search ? search : ""
        }`
      );
      // const responseTotal = await axiosInstance.get(
      //   `/funds/total?${
      //     search ? search : ""
      //   }`
      // );

      console.log("response", response);
      if (response) {
        const {
          docs,
          page: currentPage,
          totalItems,
          totalPages,
          currentPageSize,
          links,
          size: currentSize,
        } = response.data;

        dispatch({
          type: dataConstants.GET_FUNDS,
          payload: {
            funds: docs,
            // total_fund: responseTotal.data.totalBalance,
            paginate: {
              total: totalPages,
              totalItems,
              page: currentPage,
              size: currentSize,
              previousPage: links?.previousPage,
              currentPageSize,
              nextPage: links?.nextPage,
              previousNumber: links?.previous,
              nextNumber: links?.next,
            },
          },
          // payload: { funds: response.data}
        });
        dispatch({
          type: dataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: dataConstants.RESPONSE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Connection failed. Try again later",
        },
      });
      dispatch({
        type: dataConstants.LOADING,
        isLoading: false,
      });
    }
  };
};


export const GetFundIncrement = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: dataConstants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.get(
        `/funds/fund-increment`
      );
      console.log("increment", response);
      if (response) {
        dispatch({
          type: dataConstants.GET_FUND_INCREMENT_BY_ADMIN,
          payload: {
            fund_increment: response.data,
          },
          // payload: { funds: response.data}
        });
        dispatch({
          type: dataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: dataConstants.RESPONSE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Connection failed. Try again later",
        },
      });
      dispatch({
        type: dataConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const DownloadFundIncrement = (body) => {
  
  return async (dispatch) => {
    try {
      dispatch({
        type: dataConstants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.get(
        `/funds/fund-increment-download?startDate=${body.startDate}&toDate=${body.toDate}`,
        {responseType: 'blob'}
      );

      if (response) {
        const blob = new Blob([response.data], {
          type: `application/octet-stream`,
        });
        let a = document.createElement("a");
        a.download = `fund-increment-report.xlsx`;
        a.href = window.URL.createObjectURL(blob);
        a.click();
        window.URL.revokeObjectURL(a.href);

        dispatch({
          type: dataConstants.LOADING,
          isLoading: false,
        });
      }

  
 
    } catch (e) {
      dispatch({
        type: dataConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message:  "Unable to download funds",
        },
      });
      dispatch({
        type: dataConstants.LOADING,
        isLoading: false,
      });
    }
  };
};

export const DownloadMTNUsers = () => {
  
  return async (dispatch) => {
    try {
      dispatch({
        type: dataConstants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.get(
        `/users/mtn-users-download`,
        {responseType: 'blob'}
      );

      if (response) {
        const blob = new Blob([response.data], {
          type: `application/octet-stream`,
        });
        let a = document.createElement("a");
        a.download = `Mtn-Users-report.xlsx`;
        a.href = window.URL.createObjectURL(blob);
        a.click();
        window.URL.revokeObjectURL(a.href);

        dispatch({
          type: dataConstants.LOADING,
          isLoading: false,
        });
      }

  
 
    } catch (e) {
      dispatch({
        type: dataConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message:  "Unable to download MTN funds",
        },
      });
      dispatch({
        type: dataConstants.LOADING,
        isLoading: false,
      });
    }
  };
};

export const GetTotal = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: dataConstants.LOADING,
        isLoading: true,
      });

      console.log("from total");

      // const res = Promise.all([
      //   await axiosInstance.get(`/users/members/total`),
      //   await axiosInstance.get(`/funds/total`),
      //   await axiosInstance.get(`/dues/total`),
      //   await axiosInstance.get(`/loans/total`)
      // ])

      // console.log('membersResponse', res)

      const [membersResponse] = await Promise.all([
        axiosInstance.get(`/users/members/total`),
      ]);
      // const [membersResponse, fundsResponse, duesResponse, loansResponse] =
      //   await Promise.all([
      //     axiosInstance.get(`/users/members/total`),
      //     axiosInstance.get(`/funds/total`),
      //     axiosInstance.get(`/dues/total`),
      //     axiosInstance.get(`/loans/total`),
      //   ]);

      console.log("membersResponse", membersResponse);

      if (membersResponse) {
        dispatch({
          type: dataConstants.GET_TOTAL,
          payload: {
            totalMembers: membersResponse.data,
            // totalFunds: fundsResponse.data,
            // totalDues: duesResponse.data,
            // totalLoans: loansResponse.data,
          },
        });
        dispatch({
          type: dataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: dataConstants.RESPONSE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Connection failed. Try again later",
        },
      });
      dispatch({
        type: dataConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const GetTotalTotalActiveNurse = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: dataConstants.LOADING,
        isLoading: true,
      });
      const totalActiveNurse = await axiosInstance.get(`/users/active-members`);

      if (totalActiveNurse) {
        dispatch({
          type: dataConstants.GET_TOTAL_ACTIVE_NURSE,
          payload: {
            totalActiveMembers: totalActiveNurse.data,
          },
        });
        
        dispatch({
          type: dataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: dataConstants.RESPONSE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Connection failed. Try again later",
        },
      });
      dispatch({
        type: dataConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const GetTotalLoanStats = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: dataConstants.LOADING,
        isLoading: true,
      });

      const response = await axiosInstance.get(`/loans/total-stats`);

      if (response) {
        const {
          totalLoans,
          totalSubmited,
          totalInitiated,
          totalAuthorized,
          totalBooked,
          totalRejected,
          totalPaid,
        } = response.data;
        dispatch({
          type: dataConstants.GET_TOTAL_LOANS_STATS,

          payload: {
            totalLoans,
            totalSubmited,
            totalInitiated,
            totalAuthorized,
            totalBooked,
            totalRejected,
            totalPaid,
          },
        });
        dispatch({
          type: dataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: dataConstants.RESPONSE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Connection failed. Try again later",
        },
      });
      dispatch({
        type: dataConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const GetAllDues = (isPaginated, page, size, search) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: dataConstants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.get(
        `/dues?isPaginated=${isPaginated}&page=${page}&size=${size}${
          search ? search : ""
        }`
      );

      if (response) {
        const {
          docs,
          page: currentPage,
          totalItems,
          totalPages,
          currentPageSize,
          links,
          size: currentSize,
        } = response.data;

        dispatch({
          type: dataConstants.GET_DUES,
          payload: {
            dues: docs,
            paginate: {
              total: totalPages,
              totalItems,
              page: currentPage,
              size: currentSize,
              previousPage: links?.previousPage,
              currentPageSize,
              nextPage: links?.nextPage,
              previousNumber: links?.previous,
              nextNumber: links?.next,
            },
          },
        });
        dispatch({
          type: dataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: dataConstants.RESPONSE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Connection failed. Try again later",
        },
      });
      dispatch({
        type: dataConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const GetAllLoans = (isPaginated, page, size, search) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: dataConstants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.get(
        `/loans?isPaginated=${isPaginated}&page=${page}&size=${size}${
          search ? search : ""
        }`
      );
      console.log("response", response);
      if (response) {
        const {
          docs,
          page: currentPage,
          totalItems,
          totalPages,
          currentPageSize,
          links,
          size: currentSize,
        } = response.data;

        dispatch({
          type: dataConstants.GET_LOANS,
          payload: {
            loans: docs,
            paginate: {
              total: totalPages,
              totalItems,
              page: currentPage,
              size: currentSize,
              previousPage: links?.previousPage,
              currentPageSize,
              nextPage: links?.nextPage,
              previousNumber: links?.previous,
              nextNumber: links?.next,
            },
          },
        });
        dispatch({
          type: dataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: dataConstants.RESPONSE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Connection failed. Try again later",
        },
      });
      dispatch({
        type: dataConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const GetLoansForStatistics = (status) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: dataConstants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.get(
        `/loans/statistics?status=${status}`
      );
      console.log("response", response);
      if (response) {
        
        dispatch({
          type: dataConstants.GET_LOANS_STATISTICS,
          payload: {
            loansStatistics: response.data
          }
        });
        dispatch({
          type: dataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: dataConstants.RESPONSE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Connection failed. Try again later",
        },
      });
      dispatch({
        type: dataConstants.LOADING,
        isLoading: false,
      });
    }
  };
};

export const GetLoanReport = (status, fromDate, toDate) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: dataConstants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.get(
        `/loans/get_loan_report/${status}?fromDate=${fromDate}&toDate=${toDate}`,
        { responseType: "blob" }
      );

      console.log("response", response);
      if (response) {
        const blob = new Blob([response.data], {
          type: `application/octet-stream`,
        });
        let a = document.createElement("a");
        a.download = `${status}-loans-report-from-${fromDate}-to-${toDate}.xlsx`;
        a.href = window.URL.createObjectURL(blob);
        a.click();
        window.URL.revokeObjectURL(a.href);

        dispatch({
          type: dataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: dataConstants.RESPONSE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Connection failed. Try again later",
        },
      });
      dispatch({
        type: dataConstants.LOADING,
        isLoading: false,
      });
    }
  };
};

export const GetMartReport = (status, fromDate, toDate) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: dataConstants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.get(
        `/orders/get_order_report/${status}?fromDate=${fromDate}&toDate=${toDate}`,
        { responseType: "blob" }
      );

      console.log("response", response);
      if (response) {
        const blob = new Blob([response.data], {
          type: `application/octet-stream`,
        });
        let a = document.createElement("a");
        a.download = `${status}-mart-report-from-${fromDate}-to-${toDate}.xlsx`;
        a.href = window.URL.createObjectURL(blob);
        a.click();
        window.URL.revokeObjectURL(a.href);

        dispatch({
          type: dataConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: dataConstants.RESPONSE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Connection failed. Try again later",
        },
      });
      dispatch({
        type: dataConstants.LOADING,
        isLoading: false,
      });
    }
  };
};

export const ResetDataResponse = () => {
  return async (dispatch) => {
    dispatch({
      type: dataConstants.RESPONSE,
      response: {
        state: null,
        message: "",
      },
    });
  };
};
