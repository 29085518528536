/* eslint-disable  */
import {
  FormControlLabel, IconButton,
  Switch
} from "@material-ui/core";
import { debounce } from "lodash";
import * as moment from "moment";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { format } from "timeago.js";
import Loader from "../../Components/Loader";
import NotFoundComponent from "../../Components/NotFoundComponent";
import { dataActionCreators } from "../../services/Actions";
import RenderLoanPage from "../RenderLoanPage";
import { ToastContainer, toast } from "react-toastify";
import { BsArrowLeftRight } from "react-icons/bs";
import Modal from "../../AdminModel/Components/Modal";

function RejectedLoans() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { GetAppliedLoans, ResetDataResponse } = bindActionCreators(dataActionCreators, dispatch);
  const [checkSouthLoan, setCheckSouthLoan] = useState(false);
  const [checkNorthLoan, setCheckNorthLoan] = useState(false);
  const [search, setSearch] = useState(null);
  const [limit, setLimit] = useState(25);
  const data = useSelector((state) => state?.data);
  const status = "rejected"
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [loanMessage, setLoanMessage] = useState("");

  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = limit;
  const [currentPage, setCurrentPage] = useState(0);
  let timestamp = new Date().getTime();
  const { appliedLoans, paginate, isLoading, response } = data;

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;

    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(appliedLoans?.slice(itemOffset, endOffset));
    setPageCount(Number(paginate?.total));
  }, [itemOffset, itemsPerPage, appliedLoans]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    console.log("event", event);
    GetAppliedLoans(true, event.selected + 1, limit, status);

    console.log("currentPage", currentPage);

    const newOffset = (event.selected * itemsPerPage) % paginate?.total;

    setItemOffset(newOffset);
  };

  const handleSouthChange = (e) => {
    setCheckSouthLoan(e.target.checked);
  };
  const handleNorthChange = (e) => {
    setCheckNorthLoan(e.target.checked);
  };

  const onSearchTextChange = debounce((e) => {
    if (e?.target?.value) {
      setSearch(e?.target?.value ?? null);
      GetAppliedLoans(
        true,
        1,
        limit,
        status,
        `${e?.target?.value ? "&search=" + e?.target?.value : ""}`
      );
      // GetAllFunds(true, 1, limit, `${e?.target?.value ? "&search=" + e?.target?.value : ""}`)
    } else {
      setSearch(null);
      GetAppliedLoans(true, 1, limit, status);
    }
  }, 800);
  const Northern = checkNorthLoan && "Northern";
  const Southern = checkSouthLoan && "Southern";

  let sectorSeleted = "";
  if(checkNorthLoan){
    sectorSeleted = "North"
  }else if(checkSouthLoan){
    sectorSeleted = "South"
  }else{
    sectorSeleted = "No"
  }

  useEffect(()=> {
    GetAppliedLoans(true, 1, limit, status);
  },[limit])

  useEffect(() => {
    GetAppliedLoans(true, 1, limit, status);
    setCheckNorthLoan(false);
    setCheckSouthLoan(false);
  }, []);

  useEffect(() => {
    if (Northern && Southern) {
      GetAppliedLoans(true, 1, limit, status);
    } else if (Northern) {
      GetAppliedLoans(
        true,
        1,
        limit,
        status,
        `${Northern ? "&filter=" + "Northern" : ""}`
      );
    } else if (Southern) {
      GetAppliedLoans(
        true,
        1,
        limit,
        status,
        `${Southern ? "&filter=" + "Southern" : ""}`
      );
    } else {
      GetAppliedLoans(true, 1, limit, status);
    }

    // GetAppliedLoans("booked");
  }, [Southern, Northern]);

  useEffect(() => {
    GetAppliedLoans(
      true,
      1,
      limit,
      status,
      `${Northern ? "&filter=" + "Northern" : ""}`
    );
    // GetAppliedLoans("booked");
  }, [Northern]);

  useEffect(() => {
    if (response?.state === "SUCCESS") {
      toast.success(response?.message);
      setTimeout(() => {
        ResetDataResponse();
      
      }, 1500);
    } else if (response?.state === "ERROR") {
      toast.error(response?.message);
      setTimeout(() => {
        ResetDataResponse();
       
      }, 1500);
    }
  }, [response?.state]);

  return (

    <RenderLoanPage title={""} page={9}>
      {isLoading && <Loader />}
      <div className="row">
        <div className="col-md-7">
          <label htmlFor="search">Search Query:</label>
          <input
            type={"search"}
            id="search"
            placeholder="search by staff id, name"
            className="form-control"
            onChange={onSearchTextChange}
          />
        </div>
        <div className="col-md-3"></div>
        <div className="col-md-2">
          <label htmlFor="search">List to show:</label>
          <select
            className="form-control"
            onChange={(e) => setLimit(e.target.value)}
          >
            {/* <option value={15}>15</option> */}
            <option value={25}>25</option>
            <option value={30}>30</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={200}>200</option>
            <option value={300}>300</option>
            <option value={400}>400</option>
            <option value={500}>500</option>
          </select>
        </div>
      </div>

      {/* {appliedLoans.length > 0 ? ( */}
      <section className="bg-white mt-4">
        <div className="container mx-auto px-4 sm:px-8">
          <div className="py-8">
            <div className="flex items-center justify-between">
              <h2 className="text-2xl font-semibold leading-tight pb-3">
                Rejected Loans
              </h2>
              <div className="flex items-center justify-between">
                <FormControlLabel
                  control={
                    <Switch
                      checked={checkSouthLoan}
                      onChange={handleSouthChange}
                    />
                  }
                  label="South Loans"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={checkNorthLoan}
                      onChange={handleNorthChange}
                    />
                  }
                  label="North Loans"
                />
              </div>
            </div>
            {/* here */}

            <div className="-mx-1 sm:-mx-8 px-4 sm:px-8 py-0 overflow-x-auto">
              <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Name
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Loan Amount
                      </th>
                      {/* <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                              Mandate Number
                            </th>
                            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                              OTP
                            </th> */}
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Date
                      </th>
                      <th className="px-3 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Status
                      </th>
                      <th className="px-3 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Sector
                      </th>
                      <th className="px-1 py-3 border-b-2 border-gray-200 bg-gray-100"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {appliedLoans?.length > 0 ? (
                      <>
                        {appliedLoans.map((loan, index) => {

                          return (
                            <tr key={loan?._id}>
                              <td className="px-1 py-3 border-b border-gray-200 bg-white text-sm">
                                <div className="flex">
                                  <div className="flex-shrink-0 w-10 h-10">
                                    {loan?.user?.profile_image ? (
                                      <img
                                        className="w-full h-full rounded-full"
                                        src={loan?.user?.profile_image+'?'+timestamp}
                                        alt=""
                                      />
                                    ) : (
                                      <>
                                        {loan?.user?.name?.split(" ")[0] ===
                                          "Mr." ? (
                                          <img
                                            className="w-full h-full rounded-full"
                                            src="/images/dev/avarta2.jpeg"
                                            alt=""
                                          />
                                        ) : (
                                          <img
                                            className="w-full h-full rounded-full"
                                            src="/images/dev/woman-avarta.jpeg"
                                            alt=""
                                          />
                                        )}
                                      </>
                                    )}
                                  </div>
                                  <div className="ml-3">
                                    <p className="text-gray-900 whitespace-no-wrap">
                                      {loan?.user?.name}
                                    </p>
                                    <p className="text-bold text-gray-600 whitespace-no-wrap">
                                      {loan?.staff_id}
                                    </p>
                                  </div>
                                </div>
                              </td>

                              <td className="px-5 py-1 border-b border-gray-200 bg-white text-sm">
                                <p className="text-gray-900 whitespace-no-wrap">
                                  &#8373;{loan?.loanAmount}
                                </p>
                                <p className="text-gray-600 whitespace-no-wrap">
                                  MD : {loan?.monthlyDeduction}
                                </p>
                              </td>
                              {/* <td className="px-5 py-1 border-b border-gray-200 bg-white text-sm">
                                  <p className="text-gray-900 whitespace-no-wrap">
                                    {loan?.mandateNumber}
                                  </p>
                              
                                </td>
                                <td className="px-5 py-1 border-b border-gray-200 bg-white text-sm">
                                  <p className="text-gray-900 whitespace-no-wrap">
                                    {loan?.otpNumber}
                                  </p>
                               
                                </td> */}

                              <td className="px-5 py-1 border-b border-gray-200 bg-white text-sm">
                                <p className="text-gray-900 whitespace-no-wrap">
                                  {moment(loan?.created_at).format(
                                    "MMMM Do YYYY"
                                  )}
                                </p>
                                <p className="text-gray-600 whitespace-no-wrap">
                                  {/* {'time ago here'} */}
                                  {format(loan?.created_at)}
                                </p>
                              </td>
                              <td className="px-2 py-1 border-b border-gray-200 bg-white text-sm">
                                {loan?.rejected?.map((rej) => {
                                  if(rej?.status === true && rej?.editFormChecked === true){
                                   return <span 
                                   onClick={() => {
                                    setLoanMessage(loan?.rejected[0]?.message);
                                    setOpenMessageModal(true);
                                  }}
                                   className="relative cursor-pointer inline-block px-3 py-1 font-semibold text-red-900 leading-tight">
                                    <span
                                      aria-hidden
                                      className="absolute inset-0 bg-red-200 opacity-50 rounded-full"
                                    ></span>
                                    <span className="relative">
                                    {loan?.status} <BsArrowLeftRight style={{display: 'inline-block'}}/> Incomplete
                                    </span>
                                  </span>
                                  }else{
                                   return <span
                                   onClick={() => {
                                    setLoanMessage(loan?.rejected[0]?.message);
                                    setOpenMessageModal(true);
                                  }}
                                   className="relative inline-block px-3 py-1 font-semibold text-red-900 leading-tight">
                                    <span
                                      aria-hidden
                                      className="absolute inset-0 bg-red-200 opacity-50 rounded-full"
                                    ></span>
                                    <span className="relative">
                                      {loan?.status}
                                    </span>
                                  </span>
                                  }
                                })}

                              </td>
                              <td className="px-3 py-1 border-b border-gray-200 bg-white text-sm">
                                <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                  <span
                                    aria-hidden
                                    className={`absolute inset-0 ${loan?.sector === "Northern"
                                        ? "bg-red-300"
                                        : "bg-blue-300"
                                      } opacity-50 rounded-full`}
                                  ></span>
                                  <span className="relative">
                                    {loan?.sector}
                                  </span>
                                </span>
                              </td>
                              <td className="px-3 py-1 border-b border-gray-200 bg-white text-sm text-right">
                                <IconButton
                                  onClick={() => {
                                    history.push({
                                      pathname: "/loans/loan-detailed",
                                      state: { loan },
                                    });
                                  }}
                                >
                                  <button
                                    type="button"
                                    className="inline-block text-gray-500 hover:text-gray-700"
                                  >
                                    <svg
                                      className="inline-block h-6 w-6 fill-current"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M12 6a2 2 0 110-4 2 2 0 010 4zm0 8a2 2 0 110-4 2 2 0 010 4zm-2 6a2 2 0 104 0 2 2 0 00-4 0z" />
                                    </svg>
                                  </button>
                                </IconButton>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    ) : (
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <NotFoundComponent
                          title={`${sectorSeleted} Rejected loans at the monent`}
                        />
                      </div>
                    )}
                  </tbody>

                </table>
              </div>

              {appliedLoans.length > 0 && (
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="<"
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="page-num"
                  previousLinkClassName="page-num"
                  nextLinkClassName="page-num"
                  activeLinkClassName="active"
                />
              )}
            </div>

          </div>
        </div>
      </section>
      {openMessageModal && (
        <Modal
          width={"40%"}
          title={"Rejected loan application"}
          closeModal={setOpenMessageModal}
        >
          <div className="modalBody">
            <div className="message">
              <p>
                {loanMessage == ""
                  ? "No message was attached please contact the your loans head"
                  : loanMessage}
              </p>
            </div>
          </div>
          <div className="modalButton">
            <div className="btnCover">
              {/* <button type="button" className="modalBtn" onClick={handleSubmit}>
            Change
          </button> */}

              <button
                data-modal-toggle=""
                type="button"
                onClick={() => setOpenMessageModal(false)}
                className="cancelBtn text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-3 py-2.5 text-center mr-2"
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
      )}
      <ToastContainer autoClose={3000}/>
    </RenderLoanPage>

  );
}

export default RejectedLoans;
